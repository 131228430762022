[class*=illustration-] {
	position: relative;

	&::after {
		pointer-events: none;
	}
}

[class*=illustration-element-]::after {
	z-index: -1;
}

[class*=illustration-section-]::after {
	z-index: -2;
}

// Hero illustration
//.illustration-section-01::after {
//	@include illustration('illustration-section-01.svg', 100%, 362px, top);
//}

// Behind hero figure
//.illustration-element-01::after {
	//@include illustration('illustration-element-01.svg', 150%, 150%);
//}